import { useEffect } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { ssrBannerManager } from '@confluence/ssr-utilities';
import { useSessionData } from '@confluence/session-data';
import type { BannerStateContainer } from '@confluence/banners';
import { useIsSiteAdmin } from '@confluence/current-user';

import { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';

import { usePTUBannerDismissState } from './usePTUBannerDismissState';

const BANNER_NAME = 'PreviouslyTrialedUserBanner';
const BANNER_HEIGHT = 48;
const FEATURE_FLAG_PTU = 'confluence_frontend_prev_premium_trialed_users';

export const useRegisterPreviouslyTrialedUserBanner = (bannerState?: BannerStateContainer) => {
	const { edition } = useSessionData();
	const { dismissBanner, isDismissed } = usePTUBannerDismissState();
	const statsigFeatureFlagValue = FeatureGates.getExperimentValue<string>(
		FEATURE_FLAG_PTU,
		'cohort',
		'not-enrolled',
		{ fireExperimentExposure: false },
	);

	const hasOtherBanners = bannerState?.state?.banners?.some(
		(banner) => banner.name !== BANNER_NAME,
	);

	useEffect(() => {
		if (statsigFeatureFlagValue === 'experiment' || statsigFeatureFlagValue === 'control') {
			FeatureGates.manuallyLogExperimentExposure(FEATURE_FLAG_PTU);
		}
	}, [statsigFeatureFlagValue]);

	const isFeatureFlagEnabled = statsigFeatureFlagValue === 'experiment';

	const { isSiteAdmin } = useIsSiteAdmin();

	const ptuBannerShouldRender =
		isSiteAdmin &&
		edition === ConfluenceEdition.STANDARD &&
		isFeatureFlagEnabled &&
		!hasOtherBanners &&
		!isDismissed;

	if (ptuBannerShouldRender) {
		bannerState?.show(BANNER_NAME, BANNER_HEIGHT);
		if (process.env.REACT_SSR) {
			ssrBannerManager.registerBanner({
				name: BANNER_NAME,
				height: BANNER_HEIGHT,
			});
		}
	} else {
		bannerState?.hide(BANNER_NAME);
	}

	return {
		ptuBannerShouldRender,
		dismissBanner,
	};
};
