import type { ComponentProps, FC, ReactNode } from 'react';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';

import { useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';

import { SectionMessageButtonConfig } from '../SectionMessage/ChangeEditionSectionMessage';
import { UpgradeEditionButton } from '../Upgrade/UpgradeEditionButton';
import { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';
import { GradientSubtleLinkButton } from '../GradientLooknFeel/GradientSubtleLinkButton';

import { SectionMessage } from './SectionMessage';
import type { SectionMessageProps } from './SectionMessage';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BodyWrapper = styled.div({
	display: 'flex',
	gap: token('space.150', '12px'),
	flexGrow: 1,
	justifyContent: 'space-between',
});

interface MessageWrapperProps {
	topBanner?: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const MessageWrapper = styled.div<MessageWrapperProps>((props) =>
	props?.topBanner
		? {
				gap: token('space.150', '12px'),
				display: 'grid',
				gridTemplateColumns: '1fr auto',
				alignItems: 'start',
			}
		: {
				display: 'inline-block',
				flexDirection: 'column',
			},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LearnMoreLink = styled.a({
	fontWeight: 500,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	color: `${token('color.link', '#0C66E4')} !important`,
	cursor: 'pointer',
});

const messageWrapperBannerStyles = xcss({
	display: 'block',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const messageWrapperMessageStyles = xcss({
	display: 'inline',
});

type MiniSectionMessageProps = Omit<SectionMessageProps, 'buttonConfig' | 'body'> & {
	messageContent: ComponentProps<typeof FormattedMessage>;
	dismissButton?: ReactNode;
};

export const MiniSectionMessage: FC<MiniSectionMessageProps> = ({
	messageContent,
	dismissButton,
	learnMoreLink,
	topBanner = false,
	...rest
}) => {
	const { source, upgradeEditionActionSubjectId, targetEdition, onUpgradeButtonClick } = rest;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { edition } = useSessionData();
	const { formatMessage } = useIntl();
	const { isSiteAdmin } = useContext(SPAViewContext);

	const LearnMoreLinkComponent =
		learnMoreLink?.appearance === 'subtle-link' ? GradientSubtleLinkButton : LearnMoreLink;

	const onLearnMoreClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'freeLearnMore',
				source,
				attributes: {
					currentEdition: edition,
					targetEdition,
					isSiteAdmin,
					linkMessage: learnMoreLink?.messageDescriptor?.defaultMessage,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, source, edition, targetEdition, isSiteAdmin, learnMoreLink]);

	return (
		<SectionMessage
			body={
				<BodyWrapper>
					<MessageWrapper topBanner={topBanner}>
						<Box xcss={topBanner ? messageWrapperBannerStyles : messageWrapperMessageStyles}>
							<FormattedMessage {...messageContent} />{' '}
						</Box>
						{isSiteAdmin ? (
							<UpgradeEditionButton
								analyticsProps={{
									actionSubjectId: upgradeEditionActionSubjectId || '',
									source,
									attributes: {
										isSiteAdmin: true,
									},
								}}
								appearance="subtle-link"
								targetEdition={ConfluenceEdition.PREMIUM}
								onUpgradeButtonClick={onUpgradeButtonClick}
							/>
						) : (
							learnMoreLink?.messageDescriptor && (
								<LearnMoreLinkComponent
									href={learnMoreLink?.href}
									onClick={() => {
										onLearnMoreClick();
										learnMoreLink?.onClick?.();
									}}
								>
									{formatMessage(learnMoreLink.messageDescriptor)}
								</LearnMoreLinkComponent>
							)
						)}
					</MessageWrapper>
					{dismissButton}
				</BodyWrapper>
			}
			buttonConfig={SectionMessageButtonConfig.NONE}
			{...rest}
		/>
	);
};
