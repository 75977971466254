import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { Subscribe } from 'unstated';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';

import { gridSize } from '@confluence/typography-placeholder';
import { BannerStateContainer } from '@confluence/banners';
import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';

import { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';
import { MiniSectionMessage } from '../UpsellLooknFeel/MiniSectionMessage';

import { useRegisterPreviouslyTrialedUserBanner } from './useRegisterPreviouslyTrialedUserBanner';

const BANNER_DISMISS_BUTTON_SIZE = gridSize * 3;
const BANNER_DISMISS_BUTTON_RIGHT_OFFSET = gridSize * 1.25; // 10px when gridSize = 8px
const SOURCE = 'previouslyTrialedUserBanner';

const i18n = defineMessages({
	bannerText: {
		id: 'change-edition.previously-trialed-user.upsell-banner-text',
		defaultMessage:
			'Want to give Premium another try? Some teams need more than 30 days to maximize their experience on Confluence.',
		description: 'Text informing previously trailed user with CTA button',
	},
	bannerDismissButton: {
		id: 'change-edition.previously-trialed-user.upsell-banner-dismiss-button',
		defaultMessage: 'Close',
		description: 'Close button label',
	},
});

const previouslyTrialedUserBannerWrapper = xcss({
	minHeight: '48px',
});

const bannerDismissButton = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${BANNER_DISMISS_BUTTON_SIZE}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${BANNER_DISMISS_BUTTON_SIZE}px`,
	cursor: 'pointer',
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	right: `${BANNER_DISMISS_BUTTON_RIGHT_OFFSET}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BannerSpacerDiv = styled.div({
	minHeight: `48px`,
});

export interface PreviouslyTrialedUserComponentProps {
	bannerState: BannerStateContainer;
}

const PreviouslyTrialedUserComponent = ({ bannerState }: PreviouslyTrialedUserComponentProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { ptuBannerShouldRender, dismissBanner } =
		useRegisterPreviouslyTrialedUserBanner(bannerState);

	const handleOnClose = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'ptuBannerCloseButton',
				source: SOURCE,
			},
		}).fire();
		dismissBanner?.();
	}, [createAnalyticsEvent, dismissBanner]);

	const handleErrorBoundary = () => {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'unhandledPTUBannerError',
				source: SOURCE,
				actionSubject: 'ui',
				attributes: {
					attribution: Attribution.EDITIONS,
				},
			},
		}).fire();
	};

	if (!ptuBannerShouldRender) {
		return null;
	}

	if (ptuBannerShouldRender && Boolean(process.env.REACT_SSR)) {
		return <BannerSpacerDiv data-testid="ptu-banner-placeholder" />;
	}

	return (
		<TransparentErrorBoundary onError={handleErrorBoundary} attribution={Attribution.EDITIONS}>
			<Box xcss={previouslyTrialedUserBannerWrapper}>
				<MiniSectionMessage
					messageContent={i18n.bannerText}
					source={SOURCE}
					targetEdition={ConfluenceEdition.PREMIUM}
					upgradeEditionActionSubjectId="ptuBannerTryItFreeButton"
					dismissButton={
						<Box
							xcss={bannerDismissButton}
							testId="banner-dismiss-button"
							role="button"
							tabIndex={0}
							onClick={handleOnClose}
						>
							<EditorCloseIcon label={formatMessage(i18n.bannerDismissButton)} />
						</Box>
					}
					topBanner
				/>
			</Box>
		</TransparentErrorBoundary>
	);
};

export const PreviouslyTrialedUserBanner = () => {
	return (
		<Subscribe to={[BannerStateContainer]}>
			{(bannerState: BannerStateContainer) => {
				return <PreviouslyTrialedUserComponent bannerState={bannerState} />;
			}}
		</Subscribe>
	);
};
